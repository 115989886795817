import { useContext } from 'react';
import { ConfigContext } from '../../providers/ConfigContext';
import styles from './loginmodal.module.scss';
import React from 'react';

const LoginModal = (props:any) => {

    const { logInUrl, myAccountUrl } = useContext(ConfigContext)
    const signInUrl = logInUrl + "/my-scholastic/sign-in.html?ref=os";
    const orderHistoryUrl = myAccountUrl+"/my-scholastic/profile/order-history.html";

    window.addEventListener("message", (event:any) => {
        callbackLogin(event);
    }, true);
  
   const callbackLogin = (event: MessageEvent) => {
        if (event && event.origin === logInUrl) {
        const {status, data} = JSON.parse(event.data);
        if (status === 'CLOSE') {
            console.log("close broadcast accepted");
            props.setOpenSignInModal(false)
        }else if (status === 'SUCCESS') {
            localStorage.loggedIn = true;
            console.log('logged in');
            window.location.href = orderHistoryUrl;
        } else if (status === 'REDIRECT') {
            console.log(status, data);
        } else if (status === 'RESET') {
            console.log(status, data);
            //we do nothing here bc reset link will come through email
        }
        }
    }

    return(
            <div className={styles.container}>
                <iframe src={signInUrl} className={styles.iframe}></iframe>
            </div>
    );
}

export default LoginModal;