import { ReactComponent as FacebookIcon} from '../../styles/images/facebook.svg';
import { ReactComponent as InstagramIcon} from '../../styles/images/instagram.svg';
import { ReactComponent as TwitterIcon} from '../../styles/images/twitter.svg';
import { ReactComponent as PinterestIcon} from '../../styles/images/pinterest.svg';
import { ReactComponent as LinkedInIcon} from '../../styles/images/linked-in.svg';
import { ReactComponent as DownArrow} from '../../styles/images/nav-chevron-closed.svg';
import { ReactComponent as Expand} from '../../styles/images/expand.svg';
import { ReactComponent as Collapse} from '../../styles/images/collapse.svg';
import Logo from '../../styles/images/scholastic-logo-updated.png';
import styles from './footer.module.scss';
import { ConfigContext } from '../../providers/ConfigContext';
import { useContext, useState } from 'react';
import React from 'react';

const Footer = () => {
    const [section1expanded, setSection1Expanded] = useState(false);
    const [section2expanded, setSection2Expanded] = useState(false);
    const [section3expanded, setSection3Expanded] = useState(false);
    const [section4expanded, setSection4Expanded] = useState(false);
    const [seeMoreExpanded, setSeeMoreExpanded] = useState(false);

    const { logoLink, giftCardLink, bookClubsLink, bookFairsLink, educatorsLink, magazinesLink, teacherStoreLink, scholasticStoreLink } = useContext(ConfigContext);

    const toggleSectionExpand = (sectionNum: number) => {
        setSection1Expanded(false);
        setSection2Expanded(false);
        setSection3Expanded(false);
        setSection4Expanded(false);
        switch(sectionNum){
            case 1:
                setSection1Expanded(!section1expanded);
                break;
            case 2:
                setSection2Expanded(!section2expanded);
                break;
            case 3:
                setSection3Expanded(!section3expanded);
                break;
            case 4:
                setSection4Expanded(!section4expanded);
                break;
        }
    }

    return(
        <div>
            <div>
                <footer className={styles.fatFooter}>

                    <div className={`${styles.socialLinks} ${styles.gridLayout}`}>
                        <div className={styles.socialLinksInner}>
                            <a href='https://www.facebook.com/scholastic' className={`${styles.socialLink} ${styles.facebook}`} >
                                <FacebookIcon className={`${styles.downArrowIcon} ${styles.icon}`}  />
                            </a>
                            <a href='https://www.instagram.com/scholasticinc' className={`${styles.socialLink} ${styles.instagram}`} >
                                <InstagramIcon className={`${styles.downArrowIcon} ${styles.icon}`} />
                            </a>
                            <a href='https://www.twitter.com/scholastic' className={`${styles.socialLink} ${styles.twitter}`} >
                                <TwitterIcon className={`${styles.downArrowIcon} ${styles.icon}`} />
                            </a>
                            <a href='https://www.pinterest.com/scholastic' className={`${styles.socialLink} ${styles.pinterest}`} >
                                <PinterestIcon className={`${styles.downArrowIcon} ${styles.icon}`} />
                            </a>
                            <a href='https://www.linkedin.com/company/scholastic/' className={`${styles.socialLink} ${styles.linkedin}`} >
                                <LinkedInIcon className={`${styles.downArrowIcon} ${styles.icon}`} />
                            </a>
                        </div>
                    </div>

                    <div className={`${styles.footerLinks} ${styles.gridLayout}`} >

                        <div className={styles.column} id={styles.section1} onClick={() => toggleSectionExpand(1)} >
                            <span className={styles.columnTitle}>
                                <h6>Education</h6>
                                <DownArrow className={styles.downArrowIcon} />
                            </span>
                            
                            <div className={`${styles.initialLinks} ${section1expanded ? styles.show : styles.hide}`}>
                                <a href={bookClubsLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Book Clubs</span>
                                </a>
                                <a href='https://www.scholastic.com/teachers/teaching-tools/home.html' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Teaching Tools</span>
                                </a>
                                <a href={teacherStoreLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>The Scholastic Teacher Store</span>
                                </a>
                                <a href={magazinesLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Magazines+</span>
                                </a>
                                <a href='https://teachables.scholastic.com/teachables/guesthomepage.html' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Scholastic Teachables</span>
                                </a>
                            </div>

                            <div className={`${styles.seemoreLinks} ${section1expanded ? styles.show : styles.hide} ${seeMoreExpanded ? styles.showSeeMore : styles.hideSeeMore}`}>
                                <a href={educatorsLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Scholastic Education</span>
                                </a>
                                <a href='https://www.scholastic.com/teachers/teaching-tools/classroom-funds/funds-marketing.html' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Classrooms Count™</span>
                                </a>
                            </div>

                            {!seeMoreExpanded ? 
                                <span className={styles.seeMore} onClick={() => setSeeMoreExpanded(!seeMoreExpanded)} >
                                    <Expand className={styles.icon} /> 
                                    <p>More</p> 
                                </span> :
                                <span className={styles.seeMore} onClick={() => setSeeMoreExpanded(!seeMoreExpanded)}>
                                    <Collapse className={styles.icon} /> 
                                <p>Less</p> 
                            </span>}

                            <div className={styles.underline}></div>

                        </div>

                        <div className={styles.column} id={styles.section2} onClick={() => toggleSectionExpand(2)}>
                            <span className={styles.columnTitle}>
                                <h6>PARENTS</h6>
                                <DownArrow className={styles.downArrowIcon} />
                            </span>
                            
                            <div className={`${styles.initialLinks} ${section2expanded ? styles.show : styles.hide}`}>
                                <a href={bookClubsLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Book Clubs</span>
                                </a>
                                <a href={scholasticStoreLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>The Scholastic Parent Store</span>
                                </a>
                                <a href={bookFairsLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Book Fairs</span>
                                </a>
                                <a href={giftCardLink} className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>eGift Cards</span>
                                </a>
                                <a href='https://www.scholastic.com/parents/home.html' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Scholastic Parents</span>
                                </a>
                            </div>

                            <div className={`${styles.seemoreLinks} ${section2expanded ? styles.show : styles.hide} ${seeMoreExpanded ? styles.showSeeMore : styles.hideSeeMore}`}>
                                <a href='https://shop.scholastic.com/parent-ecommerce/klutz.html' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Klutz</span>
                                </a>
                            </div>

                            {!seeMoreExpanded ? 
                                <span className={styles.seeMore} onClick={() => setSeeMoreExpanded(!seeMoreExpanded)} >
                                    <Expand className={styles.icon} /> 
                                    <p>More</p> 
                                </span> :
                                <span className={styles.seeMore} onClick={() => setSeeMoreExpanded(!seeMoreExpanded)}>
                                    <Collapse className={styles.icon} /> 
                                <p>Less</p> 
                            </span>}

                            <div className={styles.underline}></div>

                        </div>

                        <div className={styles.column} id={styles.section3} onClick={() => toggleSectionExpand(3)}>
                            <span className={styles.columnTitle}>
                                <h6>OUR SITES</h6>
                                <DownArrow className={styles.downArrowIcon} />
                            </span>
                            
                            <div className={`${styles.initialLinks} ${section3expanded ? styles.show : styles.hide}`}>
                                <a href='https://kids.scholastic.com/kids/home/' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Scholastic Kids</span>
                                </a>
                                <a href='http://www.scholastic.com/librarians/' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Scholastic Librarians</span>
                                </a>
                            </div>

                            <div className={styles.underline}></div>

                        </div>

                        <div className={styles.column} id={styles.section4} onClick={() => toggleSectionExpand(4)}>
                            <span className={styles.columnTitle}>
                                <h6>ABOUT US</h6>
                                <DownArrow className={styles.downArrowIcon} />
                            </span>
                            
                            <div className={`${styles.initialLinks} ${section4expanded ? styles.show : styles.hide}`}>
                                <a href='http://www.scholastic.com/aboutscholastic/index.htm' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>About Us</span>
                                </a>
                                <a href='https://www.scholastic.com/aboutscholastic/careers/' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Careers</span>
                                </a>
                                <a href='http://mediaroom.scholastic.com/' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Media Room</span>
                                </a>
                                <a href='https://www.scholastic.com/slt' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Product Safety</span>
                                </a>
                                <a href='https://scholastic.force.com/scholasticfaqs/s/contactus' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Contact Us</span>
                                </a>
                            </div>

                            <div className={`${styles.seemoreLinks} ${section4expanded ? styles.show : styles.hide} ${seeMoreExpanded ? styles.showSeeMore : styles.hideSeeMore}`}>
                                <a href='http://investor.scholastic.com/' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Investor Relations</span>
                                </a>
                                <a href='http://emea.scholastic.com/en/global-websites' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>International</span>
                                </a>
                                <a href='http://www.scholastic.com/aboutscholastic/social-responsibility.htm' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Scholastic Gives Back</span>
                                </a>
                                <a href='https://www.scholastic.com/aboutscholastic/sustainability' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Sustainability</span>
                                </a>
                                <a href='https://www.scholastic.com/sitemap.htm' className={styles.columnLink}>
                                    <span className={styles.columnLinkText}>Scholastic Site Map</span>
                                </a>
                            </div>

                            {!seeMoreExpanded ? 
                                <span className={styles.seeMore} onClick={() => setSeeMoreExpanded(!seeMoreExpanded)} >
                                    <Expand className={styles.icon} /> 
                                    <p>More</p> 
                                </span> :
                                <span className={styles.seeMore} onClick={() => setSeeMoreExpanded(!seeMoreExpanded)}>
                                    <Collapse className={styles.icon} /> 
                                <p>Less</p> 
                            </span>}

                            <div className={styles.underline}></div>

                        </div>


                    </div>
                </footer>
            </div>

            <div>
                <footer className={styles.globalFooter}>
                    <a href={logoLink} className={styles.scholasticLogo}>
                        <img src={Logo} alt="Scholastic" />
                    </a>
                    <div className={styles.footerLinkContainer}>
                        <div className={styles.footerLinks}>
                            <a href='https://www.scholastic.com/privacy.htm' target='_blank' rel="noreferrer" className={`${styles.footerLink} ${styles.privacyPolicy}`}>
                                <span className={styles.underline}>Privacy Policy</span>
                            </a>
                            <a href='https://www.scholastic.com/terms.htm' target='_blank' rel="noreferrer" className={styles.footerLink}>Terms of Use</a>
                            <div className={styles.footerSell}>
                                <a href='https://www.scholastic.com/content/corp-home/donotsell.html' target='_blank' rel="noreferrer" className={styles.footerLink}>Do Not Sell My Info</a>
                            </div>

                            <div className={styles.footerGroup}>
                                <a href='https://www.scholastic.com/site/privacy.html#:~:text=7.%20CALIFORNIA%20PRIVACY%20NOTICE' target='_blank' rel="noreferrer" className={styles.footerLink}>California Privacy Notice</a>
                                <a href='https://www.scholastic.com/aboutscholastic/' target='_blank' rel="noreferrer" className={styles.footerLink}>About Scholastic</a>
                            </div>

                            <br/>
                        </div>
                        <div className={styles.footerDescription}>TM ® &amp; © <span>2024</span> Scholastic Inc. All Rights Reserved.
                        </div>
                        <div className={styles.cookieWarning}>
                            We use cookies, pixels & session replay technology when you navigate our site. Manage your cookie preferences <a className={styles.footerLink} href="https://www.scholastic.com/content/corp-home/donotsell.html" target='_blank' rel="noreferrer">here</a>.
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer;