import { useLocation, useHistory } from 'react-router-dom';
import { parseJSON , format } from 'date-fns';
import styles from './OrderDetails.module.scss';
import defaultImage from '../../styles/images/default_xlg.jpg';
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as DownArrow} from '../../styles/images/chevron-down.svg';
import { ReactComponent as UpArrow} from '../../styles/images/chevron-up.svg';
import { ConfigContext } from '../../providers/ConfigContext';
import analyticsService from '../../services/analytics/analyticsService';
import useProfileLinks from '../../hooks/useProfileLinks';
import useTokenOrderStatus from '../../hooks/useTokenOrderStatus';
import React from 'react';

const OrderDetails = () => {
    const [updatedSuperKitList, setUpdatedSuperKitList] = useState(Array());
    const [updatedNonKitList, setUpdatedNonKitList] = useState(Array());
    const { faqUrl } = useContext(ConfigContext);
    const profileLinks = useProfileLinks();
    let history = useHistory();
    const searchParam = useLocation().search;
    const tokenOrderStatus = useTokenOrderStatus();
    let state: any = useLocation<string>().state;
    const token = new URLSearchParams(searchParam).get('token');
    const [orderDetails, setOrderDetails] = useState<any>();
    const [superKitList, setSuperKitList] = useState<any>();
    const [nonKitList, setNonKitList] = useState<any>();
    let dumble = new analyticsService();
   
    const handleSeemoreClick = (kitType: string, index:number, outerIndex: number) => {
        let kitList = [];
        if(kitType === "superKit"){
            kitList = [...updatedSuperKitList];
            kitList[outerIndex].shipmentList[index].itemsVisible = kitList[outerIndex].shipmentList[index].lineList.length;
            setUpdatedSuperKitList([...kitList]);
        }else if (kitType === "nonKit"){
            kitList = [...updatedNonKitList];
            kitList[index].itemsVisible = kitList[index].lineList.length;
            setUpdatedNonKitList([...kitList]);
        }
    }

    const handleSeeLessClick = (kitType: string, index:number, outerIndex: number) => {
        let kitList = [];
        if(kitType === "superKit"){
            kitList = [...updatedSuperKitList];
            kitList[outerIndex].shipmentList[index].itemsVisible = 3;
            setUpdatedSuperKitList([...kitList]);
        }else if (kitType === "nonKit"){
            kitList = [...updatedNonKitList];
            kitList[index].itemsVisible = 3;
            setUpdatedNonKitList([...kitList]);
        }
    }
    
    const formatDate = (dateValue: string, dateFormat: string) => {
        const dt = new Date(dateValue);
        return(format(dt, dateFormat));
    }

    const composeImageURL = (isbn: string, rendition: number) => {
        return `https://embed.cdn.pais.scholastic.com/v1/systems/myscholastic/products/identifiers/isbn/${isbn}/cover/renditions/${rendition}`;
    }

    const handleImageError = (e:any) => {
        e.target.src = defaultImage;
    }

    const mapOrderchannel = (orderChannel:string) => {
        switch(orderChannel){
            case '23-TEACHER STORE ENTERED MANUALLY':
                return 'Teacher Store Online';
            case 'SZ-SCHOLASTIC STORE MANUAL':
                return 'Scholastic Store Online';
        }
    }

    const backToOrderStatusLookup = () => {
        history.push('/');
    }

    const backToPOSearchResults = () => {
        history.push('po_number_results', state.poSearchResultData);
    }

    const printCTA = () => {
        window.print();
    }

    const prepList = (shipmentList:Array<any>) => {
        shipmentList.map((shipment: any) => {
            if(shipment.lineList.length > 3){
                shipment.itemsVisible = 3;
            }else{
                shipment.itemsVisible = shipment.lineList.length;
            }
        });
        return [...shipmentList];
    }
    const fetchTokenOrderDetails = async () =>{
        
        if (token) {
            const {data, error} = await tokenOrderStatus(token);
            if (error) {
                console.log(error.data.error);
            } else {
                setOrderDetails(data.data.order);
                data.data.nonKitList.length ? setNonKitList(data.data.nonKitList) : setNonKitList('');
                data.data.superKitList.length ? setSuperKitList(data.data.superKitList): setSuperKitList('');
            }
        }
    }

    useEffect(() => {
        if (token) {
            fetchTokenOrderDetails();
        } else {
            setOrderDetails(state.orderDetails.order);
            state.orderDetails.superKitList.length ? setSuperKitList(state.orderDetails.superKitList) : setSuperKitList('');
            state.orderDetails.nonKitList.length ? setNonKitList(state.orderDetails.nonKitList) : setNonKitList('');
        }
    },[]);
    
    useEffect(() => {
        console.log("Use effect");
        if(nonKitList && nonKitList.length){
            setUpdatedNonKitList(prepList(nonKitList[0].shipmentList));
        }

        if(superKitList && superKitList.length){
            let tempSuperKitList: Array<any> = [...superKitList];
            //if there are multiple superkits in an order, iterating through each one.
            tempSuperKitList.map((superKitObj: any, index: number)=> {
                let superKitShipmentCount: any = 0;
                //iterating through each shipment to check how many shipments with tracking number are available.
                superKitObj.shipmentList.map((superKitShipment: any) => {
                    if(superKitShipment.status === "Shipped") {
                        superKitShipmentCount++;
                        superKitShipment.shippable = true;
                        superKitShipment.shipmentIndex = superKitShipmentCount;
                    }else{
                        superKitShipment.shippable = false;
                    }
                });
                superKitObj.shippableCount = superKitShipmentCount;
                superKitObj.shipmentList = prepList(superKitObj.shipmentList);
            });
            setUpdatedSuperKitList([...tempSuperKitList]);
        }

        dumble.initDumblePage("my scholastic:order status:order number", "Order Results");
        if (token) {
            dumble.setOrderInfo("token", "");
        } else {
            dumble.setOrderInfo(state.orderDetails.order, "");
        }

        const setLoginStatus = async () => {
            const {data, error} = await profileLinks();
            if(error && error.status == 401){
                dumble.setLoginStatus(false);
            } else if(data) {
                dumble.setLoginStatus(true);
            } else {
                dumble.setLoginStatus(false);
            }
        }

        setLoginStatus();
    }, [nonKitList, orderDetails, superKitList]);

    return(
        <div className={styles.orderDetailsWrapper}>
            <div className={styles.orderDetails}>
                <div className={styles.pageHeading}>
                    <h1>Order Details</h1>
                    <a className={styles.questionsLink} target="_blank" href={faqUrl}>Question on your order?</a>
                </div>

                <div className={styles.backToLookup}><a onClick={backToOrderStatusLookup}>Back to New Search</a></div>
                {!token && state.fromPoResults && <div className={styles.backToLookup}><a onClick={backToPOSearchResults}>Back to Search Results</a></div>}
                <div className={styles.printCta}><a onClick={printCTA}> Print</a></div>

                {!!orderDetails && <div className={styles.contentSection} data-testid="orderLevelDetails">
                    <div className={styles.detailsSection}>
                        <div className={styles.orderDate}>
                            Order Date: {formatDate(orderDetails.orderDate, 'EEEE, MM/dd/yyyy')}
                        </div>
                        <div className={styles.storeType}>
                            {mapOrderchannel(orderDetails.orderChannel)}
                        </div>
                        <div className={styles.orderNumberLabel}>Order Number</div>
                        <div className={styles.orderNumberValue}>{orderDetails.orderNumber}</div>
                    </div>
                    <div className={styles.totalsSection}>
                        <div className={styles.subValues}>
                            <label>Total: </label>
                            <span>${orderDetails.totalSellingPrice.toFixed(2)}</span>
                        </div>
                        <div className={styles.subValues}>
                            <label>Shipping/Handling: </label>
                            <span>${orderDetails.totalShipping.toFixed(2)}</span>
                        </div>
                        <div>
                            <label>Sales Tax: </label>
                            <span>${orderDetails.totalSalesTax.toFixed(2)}</span>
                        </div>
                        <div className={styles.orderTotal}>
                            <label>Order Total: </label>
                            <span>${orderDetails.orderTotal.toFixed(2)}</span>
                        </div>
                    </div>
                </div>}

                <div className={styles.separator}> </div>

            </div>

            {/* Superkit section: This is very similar to the nonKit section, but indentation is diferent, hence, classes also need to be different. */}
            {!!updatedSuperKitList && !!updatedSuperKitList.length && updatedSuperKitList.map((superKit:any, index:number) => (
                <div className={styles.orderDetailCard} key={index} data-testid="orderDetailCard">
                    <div className={styles.tracking}>
                        <div className={styles.trackingNumber}>
                            <div className={styles.trackingLabel}>{superKit.superKitName.toLowerCase()}</div>
                            <div className={styles.itemPriceSuperkit}>Item Price: ${superKit.kitPrice}</div>
                        </div>
                    </div>

                    { superKit.shipmentList.map((shipment:any, indexOuter:number) => (
                        <>
                        <div className={styles.superKitTracking} key={indexOuter} >
                            <div className={styles.trackingNumber}>
                                {shipment.shippable ? <div className={styles.trackingLabel}>Shipment {shipment.shipmentIndex} of {superKit.shippableCount} </div> : ''}
                                <div className={styles.trackingLabel}>Tracking Number</div>
                                <div className={styles.trackingValue}>
                                    {shipment.trackingNumber ? 
                                        shipment.trackingURL ? 
                                            <a href={shipment.trackingURL} target="_blank">{shipment.trackingNumber}</a> 
                                            : shipment.carrierName ? 
                                                <span>{shipment.carrierName}: {shipment.trackingNumber}</span> 
                                                : shipment.trackingNumber
                                    : "N/A"}</div>
                            </div>
                            <div className={styles.status}>
                                <div className={styles.statusLabel}>Status</div>   
                                {shipment.shipmentDate && shipment.trackingNumber && shipment.status === "Shipped" ? 
                                        <div className={styles.statusValue}>
                                            {shipment.status}
                                            <span>{formatDate(shipment.shipmentDate, 'EEEE, MM/dd/yyyy')}</span>
                                        </div>  :
                                        <div className={styles.statusValue}>{shipment.status}</div>
                                    }        
                            </div>
                            
                            <div className={styles.horizontalSeparator}></div>

                            {!!shipment.lineList && shipment.lineList.slice(0, shipment.itemsVisible).map((lineItem: any, indexInner: number) => (
                            <div key={indexInner} className={styles.lineItem}>
                                {/*This is a lineitem level loop, one for each lineitem. */}
                                <div className={styles.details}>
                                    <div className={styles.image}>
                                        <img src={composeImageURL(lineItem.isbn, 200) } onError = {(e) => handleImageError(e)} />
                                    </div>
                                    <div className={styles.itemDetails}>
                                        <div className={styles.title}>{lineItem.title.toLowerCase()}</div>
                                        <div className={styles.productType}>{lineItem.type}</div>
                                        <div className={styles.itemPrice}>ISBN: {lineItem.isbn}</div>
                                    </div>
                                </div>
                                <div className={styles.quantity}>Quantity: <span>{lineItem.quantity}</span></div>
                            </div>))}

                            {shipment.itemsVisible === 3 && shipment.lineList.length > 3 ? 
                                <div className={styles.seeMore} onClick={()=> handleSeemoreClick("superKit", indexOuter, index)}>See More <DownArrow  className={styles.downArrowIcon} /></div> : 
                                shipment.itemsVisible > 3 ?
                                    <div className={styles.seeMore} onClick={()=> handleSeeLessClick("superKit", indexOuter, index)}>See Less <UpArrow className={styles.upArrowIcon} /></div> :
                                    ""
                            }
                        </div>
                        
                        {indexOuter !== (superKit.shipmentList.length - 1) ? <div className={styles.superkitSeparator}></div> : ''}
                        </>
                    ))}
                </div>
            ))}

            {/* Non superkit section */}
            {!!updatedNonKitList && !!updatedNonKitList.length && updatedNonKitList.map((shipment:any, indexOuter:number) => (
                <div className={styles.orderDetailCard} key={indexOuter} data-testid="orderDetailCard" >
                    {/*Beginning of a card. This is a shipment level loop, one for each shipment. */}
                    <div className={styles.tracking}>
                        <div className={styles.trackingNumber}>
                            <div className={styles.trackingLabel}>Tracking Number</div>
                            <div className={styles.trackingValue}>
                                {shipment.trackingNumber ? 
                                    shipment.trackingURL ? 
                                        <a href={shipment.trackingURL} target="_blank">{shipment.trackingNumber}</a> 
                                        : shipment.carrierName ? 
                                            <span>{shipment.carrierName}: {shipment.trackingNumber}</span> 
                                            : shipment.trackingNumber
                                : "N/A"}</div>
                        </div>
                        <div className={styles.status}>
                            <div className={styles.statusLabel}>Status</div>
                            
                                {shipment.shipmentDate && shipment.trackingNumber && shipment.status === "Shipped" ? 
                                    <div className={styles.statusValue}>
                                        {shipment.status}
                                        <span>{formatDate(shipment.shipmentDate, 'EEEE, MM/dd/yyyy')}</span>
                                    </div>  :
                                    <div className={styles.statusValue}>{shipment.status}</div>
                                }
                            
                        </div>
                    </div>

                    <div className={styles.horizontalSeparator}></div>

                    {!!shipment.lineList && shipment.lineList.slice(0, shipment.itemsVisible).map((lineItem: any, indexInner: number) => (
                    <div key={indexInner} className={styles.lineItem}>
                        {/*This is a lineitem level loop, one for each lineitem. */}
                        <div className={styles.details}>
                            <div className={styles.image}>
                                <img src={composeImageURL(lineItem.isbn, 200) } onError = {(e) => handleImageError(e)} />
                            </div>
                            <div className={styles.itemDetails}>
                                <div className={styles.title}>{lineItem.title.toLowerCase()}</div>
                                <div className={styles.productType}>{lineItem.type}</div>
                                <div className={styles.itemPrice}>Item Price: ${lineItem.itemPrice}</div>
                                <div className={styles.itemPrice}>ISBN: {lineItem.isbn}</div>
                            </div>
                        </div>
                        <div className={styles.quantity}>Quantity: <span>{lineItem.quantity}</span></div>
                    </div>))}

                    {shipment.itemsVisible === 3 && shipment.lineList.length > 3 ? 
                        <div className={styles.seeMore} onClick={()=> handleSeemoreClick("nonKit", indexOuter, 0)}>See More <DownArrow  className={styles.downArrowIcon} /></div> : 
                        shipment.itemsVisible > 3 ?
                            <div className={styles.seeMore} onClick={()=> handleSeeLessClick("nonKit", indexOuter, 0)}>See Less <UpArrow className={styles.upArrowIcon} /></div> :
                            ""
                    }

                </div>
            ))}

        </div>
    );
}

export default OrderDetails;