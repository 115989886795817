import React, { useContext, useEffect, useState } from 'react';
import Logo from '../../styles/images/scholastic-logo-updated.png';
import { ReactComponent as DownArrow} from '../../styles/images/nav-chevron-closed.svg';
import { ReactComponent as UpArrow} from '../../styles/images/nav-chevron-opened.svg';
import { ReactComponent as BookClubsImage} from '../../styles/images/Image-book-clubs-@2x.svg';
import { ReactComponent as TeacherStoreImage} from '../../styles/images/Image-TSO-@2x.svg';
import { ReactComponent as ScholasticStoreImage} from '../../styles/images/Image-SSO-@2x.svg';
import { ReactComponent as EGiftCardsImage} from '../../styles/images/Image-gift-cards-@2x.svg';
import { ReactComponent as ProfileImage} from '../../styles/images/profile.svg';
import { ReactComponent as Hamburger} from '../../styles/images/hamburger.svg';
import { ReactComponent as Close} from '../../styles/images/close.svg';
import styles from './header.module.scss';
import { ConfigContext } from '../../providers/ConfigContext';
import useProfileLinks from '../../hooks/useProfileLinks';
import LoginModal from '../LoginModal/LoginModal';
import createAnalyticDataElement from '../../util/create-analytic-data-element';

interface ProfileLinksList {
    [key: string]: string;
}

const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [openSignInModal, setOpenSignInModal] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [initials, setInitials] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [profileLinksList, setProfileLinksList] = useState();
    const [myWishList, setMyWishList] = useState('');
    const [myProfile, setMyProfile] = useState('');
    const [myOrderHistory, setMyOrderHistory] = useState('');
    const [giftCard, setGiftCard] = useState('');
    const [eWallet, setEwallet] = useState('');
    const [manageCampaigns, setManageCampaigns] = useState('');
    const [subscriptionDownloads, setSubscriptionDownloads] = useState('');
    const { myAccountUrl, logoLink, giftCardLink, bookClubsLink, bookFairsLink, educatorsLink, magazinesLink, teacherStoreLink, scholasticStoreLink, tradeLink } = useContext(ConfigContext);
    const profileLinks = useProfileLinks();

    const showMenu= () => {
        setShowMobileMenu(!showMobileMenu);
    }

    const handleSignIn = () => {
        setOpenSignInModal(true);
        createAnalyticDataElement("login_analytic", "account_login", "login")
    }

    const fetchProfileLinks = async () => {
        const cookie_SPS_UD = getCookie("SPS_UD");
        if(cookie_SPS_UD){
            setLoggedIn(true);
            const cookie_SPS_UD_contents = cookie_SPS_UD.split('|');
            setFirstname(cookie_SPS_UD_contents[2]);
            setInitials(cookie_SPS_UD_contents[2][0]+cookie_SPS_UD_contents[3][0]);
        }else{
            setLoggedIn(false);
        }
        const {data, error} = await profileLinks();
            if (error) {
                console.log(error);
            }else{
                console.log(data);
                setProfileLinksList(data);
            }
    }

    const getCookie = (name: string) => {
        const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
        return (cookieValue ? cookieValue.pop() : '');
    }

    const deleteCookie = (name: string) => {
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.scholastic.com';
    };

    const signOut = () => {
        deleteCookie("SPS_UD");
        fetchProfileLinks();
    }

    const printList = () => {
        if(profileLinksList){
            const linksList:ProfileLinksList = profileLinksList;
            let entries = Object.entries(linksList);
        
            entries.map( (entry) => {
                
                switch (entry[0]){
                    case '#my-profile':
                        setMyProfile(entry[1]);
                        break;
                    case '#my-wishlist':
                        setMyWishList(entry[1]);
                        break;
                    case '#order-history':
                        setMyOrderHistory(entry[1]);
                        break;
                    case '#gift-card':
                        setGiftCard(entry[1]);
                        break;
                    case '#ewallet':
                        setEwallet(entry[1]);
                        break;
                    case '#manage-campaigns':
                        setManageCampaigns(entry[1]);
                        break;
                    case '#subscription-downloads':
                        setSubscriptionDownloads(entry[1]);
                        break;  
                }
            });
        }
        
    }

    useEffect(() => {
        fetchProfileLinks();
    }, []);

    useEffect(() => {
        printList();
    }, [profileLinksList])

    return(
        <div className={styles.globalnav}>
            {showMobileMenu ? <div className={styles.mobileDarkOverlay} ></div> : '' }
            <header className={styles.siteNav} >
                <div className={`${styles.menuIcon} ${styles.mobileOnly}`} onClick={showMenu}>
                    {showMobileMenu ?
                    <Close className={styles.icon} /> :
                    <Hamburger className={styles.icon} />}
                </div>
                <a href={logoLink} className={styles.logo} >
                    <img className={styles.scholasticLogo} src={Logo} alt="Scholastic" />
                </a>
                <nav>
                    <div className={styles.shopHeader}>
                        <p className={styles.shopText}>Shop
                            <DownArrow  className={styles.downArrowIcon} />
                            <UpArrow className={styles.upArrowIcon} />
                        </p>
                
                        <div className={styles.shopDropdown}>
                            <a id="dropdown_clubs" className={styles.shopLink} href={bookClubsLink} onMouseDown={()=>{createAnalyticDataElement("dropdown_clubs_analytic", "navigation_dropdown:Scholastic Book Clubs","dropdown_clubs")}}>
                                <div className={styles.storeDropdownInfo}>
                                    <BookClubsImage className={styles.dropDownImg} />
                                    <p className={styles.dropDownHeader}>Scholastic Book Clubs</p>

                                    <p className={styles.dropDownDescription}>Rewarding for teachers, easy for families, fun for kids—a love of reading is inside every Book Box</p>
                                </div>
                            </a>
                            <a id="dropdown_tso" className={styles.shopLink} href={teacherStoreLink} onMouseDown={()=>{createAnalyticDataElement("dropdown_tso_analytic", "navigation_dropdown:The Teacher Store","dropdown_tso")}}>
                                <div className={styles.storeDropdownInfo}>
                                    <TeacherStoreImage className={styles.dropDownImg} />
                                    <p className={styles.dropDownHeader}>The Teacher Store</p>

                                    <p className={styles.dropDownDescription}>Shop by grade, subject or format to ensure your students have the resources they need</p>
                                </div>
                            </a> 
                            <a id="dropdown_sso" className={styles.shopLink} href={scholasticStoreLink} onMouseDown={()=>{createAnalyticDataElement("dropdown_sso_analytic", "navigation_dropdown:The Scholastic Store","dropdown_sso")}}>
                                <div className={styles.storeDropdownInfo}>
                                    <ScholasticStoreImage className={styles.dropDownImg} />
                                    <p className={styles.dropDownHeader}>The Scholastic Store</p>

                                    <p className={styles.dropDownDescription}>Shop best-selling, new, classic and award-winning books and toys for kids of all ages</p>
                                </div>
                            </a> 
                            <a id="dropdown_gift" className={styles.shopLink} href={giftCardLink} onMouseDown={()=>{createAnalyticDataElement("dropdown_gift_analytic", "navigation_dropdown:Scholastic eGift Cards","dropdown_gift")}}>
                                <div className={styles.storeDropdownInfo}>
                                    <EGiftCardsImage className={styles.dropDownImg} />
                                    <p className={styles.dropDownHeader}>Scholastic eGift Cards</p>

                                    <p className={styles.dropDownDescription}>Share the joy of reading instantly with Scholastic eGift Cards</p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className={`${styles.mobileMenucontainer} ${showMobileMenu ? styles.mobileMenuShow : styles.mobileMenuHide}`}>
                        <div className={styles.navLinks}>
                            <a className={styles.mobileOnly} href={teacherStoreLink}>The Teacher Store</a>
                            <a className={styles.mobileOnly} href={scholasticStoreLink}>The Scholastic Store</a>
                            <a className={styles.mobileOnly} href={giftCardLink}>Scholastic eGift Cards</a>
                            <a id="link_clubs" href={bookClubsLink} onMouseDown={()=>{createAnalyticDataElement("link_clubs_analytic", "navigation_links:Scholastic Book Clubs","link_clubs")}}>Book Clubs</a>
                            <a id="link_fairs" href={bookFairsLink} onMouseDown={()=>{createAnalyticDataElement("link_fairs_analytic", "navigation_links:Scholastic Book Fairs","link_fairs")}}>Book Fairs</a>
                            <a id="link_educators" href={educatorsLink} onMouseDown={()=>{createAnalyticDataElement("link_educators_analytic", "navigation_links:Scholastic Educators","link_educators")}}>Education</a>
                            <a id="link_magazines" href={magazinesLink} onMouseDown={()=>{createAnalyticDataElement("link_magazines_analytic", "navigation_links:Scholastic Magazines","link_magazines")}}>Magazines</a>
                            <a id="link_trade" href={tradeLink} onMouseDown={()=>{createAnalyticDataElement("link_trade_analytic", "navigation_links:Scholastic Trade", "link_trade")}}>Trade</a>
                        </div>

                        {!!loggedIn && <div className={styles.signedIn}>
                            <div className={`${styles.signedInCircle} ${styles.desktopOnly}`}><span className={styles.profileInitials}>{initials}</span></div>
                            <div className={styles.signdInOverlay}></div>
                            <div className={`${styles.signedInOptions} ${styles.collapsed} ${styles.hasCampaigns}`} id="profile-menu">
                                <p className={styles.greeting}> Hello, 
                                    <span className={styles.name}>{firstname}</span>
                                </p>
                                {!!myProfile && <a href={`${myAccountUrl}${myProfile}`}>My Profile</a>}                                
                                {!!myWishList && <a href={`${myAccountUrl}${myWishList}`}>My Wishlist</a>}
                                {!!myOrderHistory && <a href={`${myAccountUrl}${myOrderHistory}`}>Order History</a>}
                                {!!subscriptionDownloads && <a href={`${myAccountUrl}${subscriptionDownloads}`}>Subscriptions &amp; Downloads</a>}
                                {!!manageCampaigns && <a href={`${myAccountUrl}${manageCampaigns}`}>Campaigns</a>}
                                {!!giftCard && <a href={`${myAccountUrl}${giftCard}`}>Gift Cards</a>}
                                {!!eWallet && <a href={`${myAccountUrl}${eWallet}`}>e-Wallets</a>}
                                <a href="#" onClick={signOut} >Sign Out</a>
                            </div>
                        </div>}

                        {!loggedIn && <a id="login" className={styles.profileIcon} onClick={handleSignIn}>
                            <ProfileImage className={`${styles.desktopOnly} ${styles.profileImg}`} />
                            <p className={`${styles.mobileOnly} ${styles.signInText}`}> Sign In / Register </p>
                        </a>}

                        <div className={`${styles.modal} ${openSignInModal ? styles.showIframe : styles.hideIframe}`}>
                            <div className={styles.overlay} onClick={() => {
                                    setOpenSignInModal(false);
                                }}>
                            </div>
                            {openSignInModal && <LoginModal setOpenSignInModal={setOpenSignInModal} />}
                        </div>
                    </div>

                </nav>
            </header>
        </div>
    )
}

export default Header;