import React, { createContext } from 'react';
import propTypes from 'prop-types';

const ConfigContext: React.Context<any> = createContext({});

const defaultConfig = {
  env: process.env.REACT_APP_ENV || '',
  apiBase: process.env.REACT_APP_API_BASE || '',
  logoLink: process.env.REACT_APP_LOGO_URL || '',
  giftCardLink:process.env.REACT_APP_GIFT_CARD || '',
  bookClubsLink:process.env.REACT_APP_BOOK_CLUBS || '',
  bookFairsLink:process.env.REACT_APP_BOOK_FAIRS || '',
  educatorsLink:process.env.REACT_APP_EDUCATORS || '',
  magazinesLink:process.env.REACT_APP_MAGAZINES || '',
  teacherStoreLink:process.env.REACT_APP_TEACHER_STORE || '',
  scholasticStoreLink:process.env.REACT_APP_SCHOLASTIC_STORE || '',
  logInUrl: process.env.REACT_APP_LOGIN || "",
  myAccountUrl: process.env.REACT_APP_MYACCOUNT_URL || "",
  faqUrl: process.env.REACT_APP_FAQ || "",
  tradeLink: process.env.REACT_APP_TRADE || "",
};

const ConfigProvider = ({ value, children }: any) => 
  <ConfigContext.Provider value={value}>
    {children}
  </ConfigContext.Provider>;

ConfigProvider.propTypes = {
  value: propTypes.object,
  children: propTypes.node.isRequired
};

ConfigProvider.defaultProps = {
  value: defaultConfig
}

export { ConfigContext, ConfigProvider };