import { useContext, useEffect, useState } from 'react';
import usePOCheckStatus from '../../hooks/usePoCheckStatus';
import useOrderCheckStatus from '../../hooks/useOrderCheckStatus';
import styles from './OrderStatusLookup.module.scss'
import cliffordImg from '../../styles/images/clifford.png';
import { ReactComponent as ReloadIcon} from '../../styles/images/reload-white.svg';
import { useHistory } from 'react-router-dom';
import { ConfigContext } from '../../providers/ConfigContext';
import LoginModal from '../../components/LoginModal/LoginModal';
import analyticsService from '../../services/analytics/analyticsService';
import useProfileLinks from '../../hooks/useProfileLinks';
import createAnalyticDataElement from '../../util/create-analytic-data-element';
import React from 'react';

declare global {
    interface Window { _satellite: any; }
  }
  
  window._satellite = window._satellite || {};

const OrderStatusLookup = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [webOrderNumber, setWebOrderNumber] = useState('');
    const [poNumber, setPoNumber] = useState('');
    const [webZipcode, setWebZipcode] = useState('');
    const [poZipcode, setPoZipcode] = useState('');
    const [errorStatus, setErrorStatus] = useState(200);
    const [errorMessageWeb, setErrorMessageWeb] = useState('');
    const [errorMessagePo, setErrorMessagePo] =useState('');
    const [clifford, setClifford] = useState(false);
    const checkPoStatus = usePOCheckStatus();
    const checkOrderStatus = useOrderCheckStatus();
    const { faqUrl, bookClubsLink } = useContext(ConfigContext);
    const [openSignInModal, setOpenSignInModal] = useState(false);
    const profileLinks = useProfileLinks();

    let dumble = new analyticsService();

    let history = useHistory();

    const handleSignIn = () => {
        setOpenSignInModal(true);
    }

    const handlePoNumberChange = (e:any) => {
        setPoNumber(e.target.value.replace(/[^\w\s]/gi, ""));
        setWebOrderNumber('');
        setWebZipcode('');
        setErrorMessageWeb('');
        setErrorMessagePo('');
    }

    const handleOrdernumberChange = (e:any) => {
        setWebOrderNumber(e.target.value.replace(/[^\w\s]/gi, ""));
        setPoNumber('');
        setPoZipcode('');
        setErrorMessageWeb('');
        setErrorMessagePo('');
    }

    const handleWebZipcodeChange = (e:any) => {
        setWebZipcode(e.target.value.replace(/[^\w\s]/gi, ""));
        setPoNumber('');
        setPoZipcode('');
        setErrorMessageWeb('');
        setErrorMessagePo('');
    }

    const handlePoZipCodechange = (e:any) => {
        setPoZipcode(e.target.value.replace(/[^\w\s]/gi, ""));
        setWebOrderNumber('');
        setWebZipcode('');
        setErrorMessageWeb('');
        setErrorMessagePo('');
    }

    const checkStatus = async () => {
        setIsLoading(true);
        if(webOrderNumber && !poNumber) {
            console.log('_satellite track Order Status Lookup: Event name: order-number, Property name: "Order Number"');
            window._satellite.track("order-number",{name:"Order Number"});
            console.log('_satellite track Order Status Lookup: Event name: order-number-zip-code, Property name: "Order Number Zip Code"');
            window._satellite.track('order-number-zip-code',{name: 'Order Number Zip Code'});
            const {data, error} = await checkOrderStatus(webOrderNumber.trim(), webZipcode.trim());
            if (error) {
                setErrorStatus(error.status);
                setErrorMessageWeb(error.data.message);
                setIsLoading(false);
            }else{
                setIsLoading(false);
                navigateToOrderDetails(data);
            }

        }else if(!webOrderNumber && poNumber){
            console.log('_satellite track Order Status Lookup: Event name: number, Property name: "PO Number"');
            window._satellite.track('po-number',{name: 'PO Number'});
            console.log('_satellite track Order Status Lookup: Event name: po-zip-code, Property name: "PO Zip Code"');
            window._satellite.track('po-zip-code',{name: 'PO Zip Code'});
            const {data, error} = await checkPoStatus(poNumber.trim(), poZipcode.trim(), 0, 20, 'orderNumber', 'ASC');
            if (error) {
                setErrorStatus(error.status);
                setErrorMessagePo(error.data.message);
                setIsLoading(false);
            }else{
                setIsLoading(false);
                navigateToPoResults(data);
            }
        }
        
    }

    const handleKeypress = (e:any) => {
    if (e.keyCode === 13) {
        if((webOrderNumber && webZipcode) || (poNumber && poZipcode)){
            checkStatus();
        }
      }
    };

    useEffect(() => {
        switch(errorStatus) {
            case 500:
                setClifford(true);
                break;
            case 404:
                setClifford(true);
                break;
            case 403:
                setClifford(true);
                break;
        }
    }, [errorStatus])

    useEffect(() => {
        dumble.initDumblePage("my scholastic:order status:order look up", "Order Lookup");
        const setLoginStatus = async () => {
            const {data, error} = await profileLinks();
            if(error && error.status === 401){
                dumble.setLoginStatus(false);
            } else if(data) {
                dumble.setLoginStatus(true);
            } else {
                dumble.setLoginStatus(false);
            }
        }

        setLoginStatus();
    }, [])

    const navigateToOrderDetails = (orderDetails: any) =>{
        let dataToPass = {
            orderDetails: orderDetails.data,
            fromPoResults: false
        }
        history.push('order_details', dataToPass);
    }

    const navigateToPoResults = (poResults: any) => {
        let dataToPass = {
            poResults: poResults.data.orderList,
            totalElements: poResults.data.totalElement,
            totalPages: poResults.data.totalPages,
            poNumber: poNumber,
            zipcode: poZipcode
        }
        history.push('po_number_results', dataToPass)
    }

    return (
        <div>
            {isLoading && <div className={styles.backdrop}>
                <div className={styles.loader}></div>
            </div>}
            {!clifford && <div className={styles.orderStatusLookup} data-testid="OrderStatusLookup" >
                <div className={styles.pageHeading}>
                    <h1>Order Status Lookup</h1>
                    <a id="questions" className={styles.questionsLink} target="_blank" href={faqUrl} onMouseDown={() => {
                        console.log("_satellite track Question On Your Order")
                        console.log(window._satellite.buildInfo);
                        window._satellite.track('question-on-your-order',{name: 'Question On Your Order'});
                        createAnalyticDataElement("questions_analytic", "questions_on_your_order", "questions")
                    }
                        }>Question on your order?</a>
                </div>
                <p className={styles.instructionText}>To view your order, please enter order number and zipcode <strong>OR</strong> PO number and zipcode</p>
                <div className={styles.contentSection}>
                    
                    <div className={styles.orderNumberSection}>
                        <p>Check status with order number</p>
                        <div className={styles.label}>Order Number</div>
                        <input id="orderNumber" type={"text"} value={webOrderNumber} onChange={(e) => handleOrdernumberChange(e)} onKeyDown={(e) => handleKeypress(e)} />
                        {!!errorMessageWeb && <p className={styles.error}>{errorMessageWeb} <br /> If it's a Book Clubs order, click <a href={bookClubsLink} target="-blank">here</a> to check your status.</p> }
                        <div className={styles.label}>Billing Zipcode</div>
                        <input id="orderNumberZipcode" type={"text"} value={webZipcode} onChange={(e) => handleWebZipcodeChange(e)} onKeyDown={(e) => handleKeypress(e)} />
                    </div>
                    <div className={styles.poNumberSection}>
                        <p>Check status with PO number</p>
                        <div className={styles.label}>PO Number</div>
                        <input id="poNumber" type={"text"} value={poNumber} onChange={(e) => handlePoNumberChange(e)} onKeyDown={(e) => handleKeypress(e)} />
                        {!!errorMessagePo && <p className={styles.error}>{errorMessagePo} <br /> If it's a Book Clubs order, click <a href={bookClubsLink} target="-blank">here</a> to check your status.</p> }
                        <div className={styles.label}>Billing Zipcode</div>
                        <input id="poNumberZipcode" type={"text"} value={poZipcode} onChange={(e) => handlePoZipCodechange(e)} onKeyDown={(e) => handleKeypress(e)} />
                    </div>
                </div>
                <button 
                    id="check-status"
                    data-testid='checkStatusBtn'
                    className={`${styles.button} ${styles.red} ${styles.checkStatus}`}
                    disabled = {(webOrderNumber && webZipcode) || (poNumber && poZipcode)  ? false : true }
                    onClick={() => {
                            checkStatus()
                            console.log("_satellite track Check Status")
                            console.log(window._satellite.buildInfo); 
                            window._satellite.track('check-status',{name: 'Check Status'});
                        }
                    }>
                        Check status                
                </button>
                <p className={styles.signIn}>Already have a Scholastic account?
                    <a onClick={() => {
                        console.log("_satellite track Check Status")
                        console.log(window._satellite.buildInfo); 
                        window._satellite.track('log-in-here',{name: 'Log In Here'});
                        handleSignIn()
                    }
                }>Log In here</a>
                </p>

                <div className={`${styles.modal} ${openSignInModal ? styles.showIframe : styles.hideIframe}`}>
                    <div className={styles.overlay} onClick={() => setOpenSignInModal(false)}></div>
                    {openSignInModal && <LoginModal setOpenSignInModal={setOpenSignInModal} />}
                </div>
            </div>}
            
            {clifford && <div className={styles.cliffordContainer}>
                <img src={cliffordImg} alt="Clifford" />
                <h3>We're sorry for the paws in service.</h3>
                <p>Please refresh your browser.</p>
                <button
                    id="reload"
                    className={`${styles.button} ${styles.red} ${styles.reload}`}
                    onClick={() => window.location.reload()}>
                        <ReloadIcon className={styles.icon} />Reload
                </button>
            </div>}
        </div>
    )
}

export default OrderStatusLookup;