import { useContext } from "react";
import { ConfigContext } from "../providers/ConfigContext";
import useMakeApiRequest from "./useMakeApiRequest";

const useProfileLinks = () => {
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('GET');

    const profileLinks = async () => {
        return await makeApiRequest(`${apiBase}/profile-links`);
    }

    return profileLinks
}

export default useProfileLinks;