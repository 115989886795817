import { useContext, useEffect, useState, ChangeEvent } from "react";
import usePOCheckStatus from '../../hooks/usePoCheckStatus';
import useOrderCheckStatus from '../../hooks/useOrderCheckStatus';
import { useHistory, useLocation } from "react-router-dom";
import { format } from 'date-fns';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from './PONumberResults.module.scss';
import cliffordImg from '../../styles/images/clifford.png';
import { ReactComponent as ReloadIcon} from '../../styles/images/reload-white.svg';
import { ConfigContext } from "../../providers/ConfigContext";
import analyticsService from "../../services/analytics/analyticsService";
import useProfileLinks from "../../hooks/useProfileLinks";
import useTokenPoStatus from '../../hooks/useTokenPoStatus';
import React from 'react';


const PONumberResults = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [clifford, setClifford] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [orderList, setOrderList] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [errorMessage, setErrorMessage] = useState('');
    const [sortBy, setSortBy] = useState('orderNumber');
    const [sortType, setSortType] = useState<string>('ASC');
    const checkPoStatus = usePOCheckStatus();
    const checkOrderStatus = useOrderCheckStatus();
    const { faqUrl } = useContext(ConfigContext);
    const profileLinks = useProfileLinks();
    const searchParam = useLocation().search;
    const tokenPoStatus = useTokenPoStatus();
    const token = new URLSearchParams(searchParam).get('token');

    let history = useHistory();
    var state: any = useLocation<string>().state;

    let dumble = new analyticsService();

    const handleOrderNumberClick = async (orderNumber: string) => {
        setIsLoading(true);
        const {data, error} = await checkOrderStatus(orderNumber, state.zipcode);
            if (error) {
                setClifford(true);
                setIsLoading(false);
            }else{
                setIsLoading(false);
                navigateToOrderDetails(data);
            }
    }

    const navigateToOrderDetails = (orderDetails: any) =>{
        let dataToPass = {
            orderDetails: orderDetails.data,
            fromPoResults: true,
            poSearchResultData: state
        }
        history.push('order_details', dataToPass);
    }

    const backToOrderStatusLookup = () => {
        history.push('/');
    }

    const printCTA = () => {
        window.print();
    }

    const formatDate = (dateValue: string, dateFormat: string) => {
        const dt = new Date(dateValue);
        return(format(dt, dateFormat));
    }

    const handlePaginationChange =  (e:ChangeEvent<unknown>, value: number ) => {
        setCurrentPage(value - 1);
    }

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
        setCurrentPage(0);
    }

    const fetchPOResults = async () => {
        setIsLoading(true);
        const {data, error} = await checkPoStatus(state.poNumber, state.zipcode, currentPage, pageSize, sortBy, sortType);
        if (error) {
            setErrorMessage(error.data.message);
            setIsLoading(false);
            console.log(error);
        }else{
            setIsLoading(false);
            setOrderList(data.data.orderList)
            setTotalPages(data.data.totalPages);
        }
    }

    const fetchTokenPOResults = async () =>{
        const {data, error} = await tokenPoStatus(token);
        if (error) {
            setErrorMessage(error.data.error);
            setIsLoading(false);
            console.log(error);
        }else{
            setIsLoading(false);
            setOrderList(data.data.orderList)
        }
    }
    
    const changeSortByOption = (e: any) => {
        setSortBy(e.target.value);
    }

    const sortByType = (e : any) =>{
        setSortType(e.target.value);
    }

    const displayOrderList = () => {
        return (
            !!orderList && orderList.map((order: any, index: number) => (
                <div className={styles.orderRow}>
                    
                        <div className={`${styles.labels} ${styles.orderNumberLabel} ${index ? styles.hideLabel : styles.showlabel}`} >Order Number</div>
                        <div className={`${styles.labels} ${styles.orderDateLabel} ${index ? styles.hideLabel : styles.showlabel}`} >Date Ordered</div>
                        <div className={`${styles.labels} ${styles.orderShipToLabel} ${index ? styles.hideLabel : styles.showlabel}`} >Ship To</div>
                    
                        <div className={`${styles.values} ${styles.orderNumberValue}`} onClick={() => token? '' : handleOrderNumberClick(order.orderNumber)}>{order.orderNumber}</div>
                        <div className={`${styles.values} ${styles.orderDateValue}`}>{order.orderDate ? formatDate(order.orderDate, 'LLLL do, yyyy') : ''}</div>
                        <div className={`${styles.values} ${styles.orderShipToValue}`}>{order.shipToPartyName ? order.shipToPartyName.toLowerCase() : ''}</div>
                    
                </div>
            ))
        )
    }

    useEffect(() =>{
        if (token) {
            fetchTokenPOResults();
        } else {
            fetchPOResults();
        }
    }, [currentPage, pageSize, sortBy, sortType])
    
    useEffect(() => {
        dumble.initDumblePage("my scholastic:order status:po number", "Order Results");
        if (token) {
            dumble.setOrderInfo("", "token");
        } else {
            dumble.setOrderInfo("", state.poNumber);
        }
        const setLoginStatus = async () => {
            const {data, error} = await profileLinks();
            if(error && error.status == 401){
                dumble.setLoginStatus(false);
            } else if(data) {
                dumble.setLoginStatus(true);
            } else {
                dumble.setLoginStatus(false);
            }
        }
        setLoginStatus();
        if (!token) {
            setOrderList(state.poResults);
            setTotalPages(state.totalPages);
        }
        
    }, []);
    
    return(
        <div>
            {isLoading && <div className={styles.backdrop}>
                <div className={styles.loader}></div>
            </div>}
            {!clifford && <div className={styles.poDetailsWrapper} data-testid="poDetailsWrapper">
                <div className={styles.poDetails}>
                    <div className={styles.pageHeading}>
                        <h1>PO Number Results</h1>
                        <a className={styles.questionsLink} target="_blank" href={faqUrl}>Question on your order?</a>
                    </div>
                    <div className={styles.backToLookup}><a onClick={backToOrderStatusLookup}>Back to New Search</a></div>
                    <div className={styles.printCta}><a onClick={printCTA}> Print</a></div>

                    <div className={styles.poNumbersCard}>
                        <div className={styles.poNumberDetails}>
                            <div className={styles.label}>PO Number</div>
                            <div className={styles.value}>{token? "token" : state.poNumber}</div>
                            <div className={styles.label}>Zip Code</div>
                            <div className={styles.value}>{token? "token" : state.zipcode}</div>                     
                        </div>

                        <div className={styles.sortByContainer}>
                            <div className={styles.sortContainer}>
                                <div className={styles.sortByInfo}>
                                    <div className={styles.sortByText}>
                                        Sort By
                                    </div>
                                    <select className={styles.sortByDropdown} id="sortByDropdown" onChange={changeSortByOption} disabled = {token? true : false}>
                                        <option value="orderNumber">Order Number</option>
                                        <option value="shipTo">School Name</option>
                                        <option value="dateOrdered">Date Ordered</option>
                                    </select>
                                </div>
                                <div className={styles.sortByInfo}>
                                    <div className={styles.sortByText}>
                                        Sort Type
                                    </div>
                                    <select className={styles.sortByDropdown} id={styles.sortType} onChange={sortByType} disabled = {token? true : false}>
                                        <option value="ASC">Ascending</option>
                                        <option value="DESC">Descending</option>
                                    </select>
                                </div>

                            </div>
                        </div>   


                        {!!errorMessage && <p className={styles.error}>{errorMessage}</p>}

                        {!token && 
                            <div className={styles.buttonsWrapper}>
                                <div className={styles.buttonsLabel} >
                                    <span>Items Per Page:</span>
                                </div>
                                <ul className={styles.buttonsInner}>
                                    <li className={`${styles.buttonItem} ${pageSize === 20 ? styles.selected : ''}`} onClick={() => handlePageSizeChange(20)} >20</li>
                                    <li className={`${styles.buttonItem} ${pageSize === 60 ? styles.selected : ''}`} onClick={() => handlePageSizeChange(60)} >60</li>
                                    <li className={`${styles.buttonItem} ${pageSize === 80 ? styles.selected : ''}`} onClick={() => handlePageSizeChange(80)} >80</li>
                                </ul>
                            </div>
                        }
                        
                        <div className={styles.poContentSection}>
                            {displayOrderList()}
                        </div>

                        <div className={styles.paginationWrapper}>
                            <Pagination 
                                count={totalPages} 
                                page={currentPage+1} 
                                onChange={handlePaginationChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                      slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                      {...item}
                                    />
                                  )} />
                        </div>
                    </div>
                </div>
            </div>}

            {clifford && <div className={styles.cliffordContainer}>
                <img src={cliffordImg} alt="Clifford" />
                <h3>We're sorry for the paws in service.</h3>
                <p>Please refresh your browser.</p>
                <button
                    id="reload"
                    className={`${styles.button} ${styles.red} ${styles.reload}`}
                    onClick={() => window.location.reload()}>
                        <ReloadIcon className={styles.icon} />Reload
                </button>
            </div>}
        </div>
    );
}

export default PONumberResults;