import { useContext } from "react";
import { ConfigContext } from "../providers/ConfigContext";
import useMakeApiRequest from "./useMakeApiRequest";

const useOrderCheckStatus = () => {
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('GET');

    const checkStatus = async (orderNumber: string, zipcode: string) => {
        return await makeApiRequest(`${apiBase}/api/v1/orderLookup?orderNumber=${orderNumber}&zipcode=${zipcode}`)
        //return await makeApiRequest('Superkit_Sample.json');
    }

    return checkStatus
}

export default useOrderCheckStatus;