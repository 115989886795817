import { useContext } from "react";
import { ConfigContext } from "../providers/ConfigContext";
import useMakeApiRequest from "./useMakeApiRequest";

const usePOCheckStatus = () => {
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('GET');

    const checkStatus = async (poNumber: string, zipcode: string, pageNumber: number, pageSize: number, sortBy: string, sortType: string) => {
        return await makeApiRequest(`${apiBase}/api/v1/search?poNumber=${poNumber}&zipcode=${zipcode}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortType=${sortType}`)
        //return await makeApiRequest('PO_search_sample.json');
    }

    return checkStatus
}

export default usePOCheckStatus;