import axios from "axios";
import { stringify } from "querystring";

class analyticsService {
    dumbleData: any;

    constructor(){
        this.dumbleData = {
            domain: {
                audience: "",
                channel: "",
                experience: "",
                experienceType: "",
                name: "",
            },
            page: {
                name: "",
                type: "", 
                template: ""
            },
            orderInfo: {
                orderID: "",
                PONumber: ""
            },
            loginStatus: false,
        };
    }

    initDumbleData() {
        const dumble = {
            domain: {
                audience: "All",
                channel: "order status",
                experience: "my scholastic",
                experienceType: "All",
                name: "All",
            }
        }
        this.updateDumbleData("domain", dumble.domain);
    }

    updateDumbleData(fieldName: any, fieldValue: any){
        // updates the fieldName inside dumbleData to the fieldValue
      if (typeof(fieldValue) === "object") {
          if (this.dumbleData[fieldName] === undefined){
              this.dumbleData[fieldName] = {};
          }
          Object.assign(this.dumbleData[fieldName], fieldValue);
      } else {
          this.dumbleData[fieldName] = fieldValue;
      }
      window.dumbleData = this.dumbleData;
    }

    initDumblePage(pageName: string, pageType: string) {
        this.initDumbleData();
        const dumblePage = {
            page: {
                name: pageName,
                type: pageType,
                template: "order status"
            }
        }
        this.updateDumbleData("page", dumblePage.page);
    }

    setOrderInfo(orderID: string, PONumber: string) {
        const dumbleOrderInfo = {
            orderInfo: {
                orderID: orderID,
                PONumber: PONumber
            }
        }
        this.updateDumbleData("orderInfo", dumbleOrderInfo.orderInfo);
    }

    setLoginStatus(loginStatus: boolean) {
        this.updateDumbleData("loginStatus", loginStatus);
    }
}

export default analyticsService;