import { useContext } from "react";
import { ConfigContext } from "../providers/ConfigContext";
import useMakeApiRequest from "./useMakeApiRequest";

const useTokenOrderStatus = () =>{
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('GET');

    const tokenOrderStatus = async (token: any) => {
        return await makeApiRequest(`${apiBase}/api/v1/parseToken?token=${token}`);
    }

    return tokenOrderStatus;

} 
export default useTokenOrderStatus;