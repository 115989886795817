import axios from "axios";

export interface APIResponse {
  data: any;
  error: any;
}

const useMakeApiRequest = (type: string) => {

  const http = axios.create({
    withCredentials: true,
  });

  const makeGetRequest = async (url: string) => {
    const apiResponse: APIResponse = {
      data: null,
      error: null,
    };

    try {
      const { data } = await http.get(url);
      apiResponse.data = data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.status) {
        apiResponse.error = err.response;
      } else {
        apiResponse.error = {
          status: "500",
        };
      }
    }
    return apiResponse;
  };

  const makePostRequest = async (url: string, requestData?: any) => {
    const apiResponse: APIResponse = {
      data: null,
      error: null,
    };

    await http
      .post(url, requestData)
      .then((response) => {
        apiResponse.data = response.data;
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.status) {
          apiResponse.error = err.response;
        } else {
          apiResponse.error = {
            status: "500",
          };
        }
      });
    return apiResponse;
  };

  // add future types implementation here
  switch (type) {
    case "GET":
      return makeGetRequest;
    case "POST":
      return makePostRequest;
    default:
      return makeGetRequest;
  }
};

export default useMakeApiRequest;
