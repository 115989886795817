const createAnalyticDataElement = (id: string, linkname: string, parentId: string) => {
	const dataElementId = document.getElementById(id);
	if(!dataElementId){
			const dataElementDiv = document.createElement("div");
			dataElementDiv.setAttribute("id", id);
			dataElementDiv.setAttribute("data-element-linkname", linkname);
			document.getElementById(parentId)?.appendChild(dataElementDiv);
	}
}

export default createAnalyticDataElement;