import { useContext } from "react";
import { ConfigContext } from "../providers/ConfigContext";
import useMakeApiRequest from "./useMakeApiRequest";

const useTokenPoStatus = () =>{
    const { apiBase } = useContext(ConfigContext);
    const makeApiRequest = useMakeApiRequest('GET');

    const tokenPoStatus = async (token: any) => {
        return await makeApiRequest(`${apiBase}/api/v1/parseToken?token=${token}`);
    }

    return tokenPoStatus;

} 
export default useTokenPoStatus;