import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import OrderStatusLookup from "./pages/OrderStatusLookup/OrderStatusLookup";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import PONumberResults from "./pages/PONumberResults/PONumberResults";
import StatementPage from "./pages/StatementPage/StatementPage";
import { Switch, Route, useHistory } from "react-router-dom";
import "./App.css";
import React from 'react';


function App() {

  let history = useHistory();
  
  return (
    <div className="mainDiv">
      <Header />
          <Switch>
            <Route path='/order_details' component={OrderDetails} />
            <Route path='/po_number_results' component={PONumberResults} />
            {/* <Route path='/statementPage' component={StatementPage}/> */}
            <Route path='/' component={OrderStatusLookup} />
          </Switch>
      <Footer />
    </div>
  );
}

export default App;
